import React from "react";
import { GreenEllipse, RedEllipse, RightArrowIcon } from "../../../Icons";

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className="flex justify-between cursor-pointer transition-all ease-in-out duration-500 borderAnimation border border-popup-bg shadow-sm rounded-3xl px-6 py-8 bg-primarycard-gradient"
    >
      <div className="flex">
        {card.status === "Active" ? (
          <img
            src="assets/logos/otherCardBg.jpg"
            alt="Other Cards"
            className="rounded-md"
          />
        ) : (
          <img
            src="assets/logos/blockedOtherCardBg.jpg"
            alt="Other Cards"
            className="rounded-md"
          />
        )}
        <div className="ml-3">
          <p className="text-body-text text-sm font-medium">{card.name}</p>
          <span className="flex items-center justify-center space-x-2">
            {card.status === "Active" ? <GreenEllipse /> : <RedEllipse />}
            <p className="text-paragraph-text text-sm ">
              {card.status} | · · {card.card_number.slice(-4)}
            </p>
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center text-body-text text-sm font-medium leading-tight tracking-tight">
        <RightArrowIcon />
      </div>
    </div>
  );
};

export default Card;
