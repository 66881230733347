import React, { useEffect, useRef } from "react";
import CommonButton from "../../Buttons/CommonButton";
import SmallButton from "../../Buttons/SmallButton";

const EnterOTP = ({
  handleOTP,
  otp,
  setOtp,
  error,
  setError,
  resendDisabled,
  counter,
  handleResendCode,
  isButtonDisabled
}) => {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Ensure only one character is taken
    setOtp(newOtp);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    } else if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
      setError("");
    } else if (index === 6 && value) {
      inputRefs[index].current.blur();
      handleOTP();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const newOtp = paste.split("").slice(0, 6); // Only take the first 6 characters
  
    if (newOtp.length > 0) {
      // Update the state with the new OTP values
      setOtp((prevOtp) => {
        const updatedOtp = [...prevOtp];
        newOtp.forEach((value, index) => {
          updatedOtp[index] = value;
        });
        return updatedOtp;
      });
  
      setError(""); // Clear error if any
  
      // Populate input fields and focus the next field
      newOtp.forEach((value, index) => {
        if (inputRefs[index] && inputRefs[index].current) {
          inputRefs[index].current.value = value;
        }
      });
  
      
    }
  };

  useEffect(() => {
    // Automatically trigger OTP submission if 6 characters are pasted
    if (otp.length === 6 && !otp.includes("") && !error) {
      setTimeout(() => {
        handleOTP();
      }, 200);
    } // eslint-disable-next-line
  }, [otp]);

  

  return (
    <>
      <p className="text-body-text text-sm font-normal leading-tight tracking-tight">
        For security purposes, please enter the six digits OTP sent to your
        registered email address.
      </p>

      <div className="flex md:space-x-2 space-x-1 mt-4">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="number"
            placeholder="*"
            maxLength={1}
            className={`md:w-14 w-[3.2rem] h-[3.2rem] md:h-14 max-[375px]:w-12 max-[375px]:h-12 rounded-xl no-arrows text-center border-[1.6px] bg-popup-bg text-body-text focus:ring-0 focus:border-primary-item font-bold
            ${error ? "border-highlighter-red" : " border-divider-border"}`}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onClick={(e) => e.target.focus()}
            onPaste={handlePaste}
          />
        ))}
      </div>
      {error && (
        <p className="text-highlighter-red text-sm font-normal leading-tight tracking-tight mt-3">
          {error}
        </p>
      )}

      <div className="flex flex-col items-center justify-center space-y-4 my-6">
        <CommonButton
          onClick={handleOTP}
          className="w-full py-3 bg-button-bg hover:bg-button-hover-bg text-button-text rounded-full font-bold disabled:bg-[#EF7D22] disabled:bg-opacity-50"
          disabled={otp.includes("") || isButtonDisabled}
        >
          Confirm
        </CommonButton>

        <SmallButton
          onClick={handleResendCode}
          disabled={resendDisabled || isButtonDisabled}
          text={`Resend the code${resendDisabled ? ` (${counter}s)` : ""}`}
        />
      </div>
    </>
  );
};

export default EnterOTP;
