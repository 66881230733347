import React from "react";
import { ActiveCard } from "./partials/ActiveCard";
import ButtonCardType from "../partials/ButtonCardType";

const PrimaryCardBG = ({ activeCard, type }) => {
  return (
    <>
      <div
        className={`max-h-[344px]  space-y-6 border border-dropdown-popup-bg bg-primarycard-gradient  py-6 shadow-box-shadow flex flex-col items-center rounded-3xl`}
      >
        <ActiveCard activeCard={activeCard} />
        <div className="flex items-center justify-between md:space-x-8 space-x-4">
          {ButtonCardType(type, activeCard)}
        </div>
      </div>
    </>
  );
};

export default PrimaryCardBG;
