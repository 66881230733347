import { useState, useEffect, useMemo } from "react";
import { Menu } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Common/SideBar";
import { BellIcon, TrashIcon } from "../../Icons";
import SmallButton from "../../Buttons/SmallButton";
import {
  deleteAllNotification,
  deleteNotification,
  readAllNotification,
} from "../../../redux/actions/notification";
import EmptyNotification from "../../Empty/EmptyNotification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NotificationSidebar = ({ getUserNotification }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 375) {
        setSidebarOpen(false);
      }
    };
  
    const debounceResize = () => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(handleResize, 200); // Debounce the resize event
      };
    };
  
    window.addEventListener("resize", debounceResize());
    return () => {
      window.removeEventListener("resize", debounceResize());
    };
  }, []);
  

  const ReadAllNotification = async () => {
    await dispatch(readAllNotification());
  };

  const handleDeleteNotification = async (notificationId) => {
    await dispatch(deleteNotification(notificationId));
  };

  const handleDeleteAllNotification = async () => {
    await dispatch(deleteAllNotification());
  };

  const unreadNotificationCount = useMemo(() => {
    return notifications.filter((item) => item.status === "unread").length;
  }, [notifications]);

  return (
    <div>
      <div
        onClick={() => setSidebarOpen(!sidebarOpen)}
        className={classNames(
          sidebarOpen
            ? "bg-active-state-bg"
            : "hover:bg-hover-state-bg bg-primary-Icon-bg",
          "w-12 h-12 px-3 py-[7px] rounded-[100px] justify-center items-center gap-2.5 inline-flex relative"
        )}
      >
        <BellIcon className="h-6 w-6 text-primary-Icon-bg" />
        {unreadNotificationCount > 0 && (
          <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 max-md:px-1.5 p-1 bg-highlighter-red rounded-full text-white text-xs flex justify-center items-center animate-pulse">
            {unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}
          </span>
        )}
      </div>
      <Sidebar
        header={true}
        title={"Notifications"}
        open={sidebarOpen}
        crossButton={true}
        crossButtonAction={() => setSidebarOpen(false)}
      >
        <Menu
          as="div"
          className="bg-popup-bg z-10 divide-gray-100 max-h-[75vh] overflow-scroll hide-scrollbar"
        >
          <div className="flex justify-between items-center sticky top-0 bg-popup-bg z-40 pb-1">
            <SmallButton
              onClick={handleDeleteAllNotification}
              text={"Delete all"}
            />
            <SmallButton
              onClick={ReadAllNotification}
              text={"Mark all as read"}
            />
          </div>
          {notifications.length === 0 ? (
            <EmptyNotification getUserNotification={getUserNotification} />
          ) : (
            notifications.map((item) => (
              <Notification
                key={item.id}
                notificationId={item.id}
                photo_url={item.photo_url}
                title={item.title}
                message={item.message}
                status={item.status}
                date={item.date}
                onDelete={handleDeleteNotification}
              />
            ))
          )}
        </Menu>
      </Sidebar>
    </div>
  );
};

const Notification = ({
  notificationId,
  photo_url,
  title,
  message,
  status,
  date,
  onDelete,
}) => {
  const [showTrashIcon, setShowTrashIcon] = useState(false);

  const handleNotificationClick = (e) => {
    e.preventDefault();
    setShowTrashIcon(!showTrashIcon);
  };

  return (
    <div className="my-2 cursor-pointer">
      <Menu.Item>
        <div
          onClick={handleNotificationClick}
          className={`
            ${
              status === "unread" ? "bg-option-list-selected" : "bg-transparent"
            }
            w-full p-3 rounded-3xl justify-start items-start gap-4 inline-flex
            relative
          `}
        >
          <div
            className={`
              ${status === "unread" ? "bg-white" : "bg-gray-300"}
              w-10 h-10 relative rounded-full justify-center flex items-center
            `}
          >
            {photo_url ? (
              <img
                className="rounded-full"
                src={photo_url}
                alt="notification"
              />
            ) : (
              <img
                className="rounded-full"
                src={require("../../../assets/images/profile.png")}
                alt="notification"
              />
            )}
            {status === "unread" ? (
              <span className="w-2.5 h-2.5 left-[28px] top-[1px] absolute bg-highlighter-red rounded-full animate-pulse" />
            ) : (
              ""
            )}
          </div>
          <div className="flex-col justify-start items-start gap-1 inline-flex">
            <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
              {title}
            </div>
            <div className="w-[184px] text-paragraph-text text-sm font-normal leading-tight tracking-tight">
              {message}
            </div>
            <div className="text-paragraph-text opacity-80 text-xs font-normal leading-tight tracking-tight">
              {date}
            </div>
          </div>
          {showTrashIcon && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <TrashIcon
                className="w-6 h-6"
                onClick={() => onDelete(notificationId)}
              />
            </div>
          )}
        </div>
      </Menu.Item>
    </div>
  );
};

export default NotificationSidebar;
