import React, { useEffect, useCallback } from "react";
import { FormatBalance, FormatDate } from "../../../helper";
import LoadMoreTransactions from "../../Skeleton/LoadMoreTransactions";
import EmptyRecentTransactions from "../../Empty/EmptyRecentTransactions";
import { AllTransactionsTableSkeleton } from "../../Skeleton";
import { GetTransactionsTypeIcon } from "../../../helper";
import StatusIcon from "../../Common/StatusIcon";

const Table = ({
  transactions,
  handleSelectTransaction,
  activeTransaction,
  loadMoreTransactions,
  loading,
  hasMore,
  setPage,
  page,
  scrollRef,
  loadMore,
  type,
  currencyCode,
}) => {
  const handleScroll = useCallback(() => {
    const container = scrollRef.current;
    let isScrollToBottom =
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10;
    if (isScrollToBottom && hasMore && !loading) {
      setPage((prev) => prev + 1);
      loadMoreTransactions(page + 1);
    }
  }, [hasMore, loading, page, loadMoreTransactions, setPage, scrollRef]);

  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={scrollRef}
      className="shadow-sm md:h-[670px] h-[470px] w-full overflow-y-auto rounded-3xl dark:bg-secondary-icon-bg bg-table-body-gradient hide-scrollbar"
    >
      {loading ? (
        <AllTransactionsTableSkeleton />
      ) : (
        <>
          <table className="w-full table">
            <thead>
              <tr>
                {["Date", "Transaction", "Status", "Amount"].map(
                  (heading, index) => (
                    <th
                      key={index}
                      className={`bg-popup-bg dark:bg-table-header-bg sticky top-0 border-b border-divider-border z-[1] py-3.5 pl-4 pr-3 text-left text-xs font-medium text-paragraph-text sm:pl-6 lg:pl-8 ${
                        heading === "Date" ? "hidden md:table-cell" : ""
                      } 
                      ${heading === "Status" ? "max-[375px]:hidden" : ""}
                      `}
                    >
                      {heading}
                    </th>
                  )
                )}
              </tr>
            </thead>

            <tbody className="dark:bg-table-row-bg bg-primarycard-gradient">
              <tr className="h-2"></tr>
                {transactions.map((transaction, index) => {
                  const isActive = activeTransaction?.id === transaction.id;
                  const transactionDate = FormatDate(
                    transaction.date,
                    "d m, yyyy"
                  );
                  const isCredit = transaction.type === "Credit";
                  const currencyFillColor = isCredit
                    ? "highlighter-green"
                    : "highlighter-red";

                  return (
                    <React.Fragment key={transaction.id}>
                      <tr
                        onClick={() => handleSelectTransaction(transaction)}
                        className={`cursor-pointer hover:bg-hover-state-bg ${
                          isActive ? "bg-active-state-bg" : ""
                        }`}
                      >
                        <td className="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-1 text-sm font-medium text-body-text sm:pl-6 rounded-l-3xl">
                          <span>{transactionDate}</span>
                        </td>
                        <td className="max-w-[200px] pl-2 py-4 md:pl-4 text-sm font-medium text-body-text md:table-cell md:rounded-none rounded-l-3xl">
                          <div className="flex items-center justify-start w-full">
                            {GetTransactionsTypeIcon(type, transaction.type)}

                            <div className="flex flex-col ml-3">
                              <p className="line-clamp-1 md:max-w-28 max-w-24 text-sm font-medium w-full">
                                {transaction.remarks}
                              </p>
                              <span className="text-paragraph-text text-xs block md:hidden">
                                {transactionDate}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap table-cell py-4 max-[375px]:hidden pr-3 text-sm font-medium text-body-text sm:pl-4">
                          <div className="flex items-center space-x-1">
                            <StatusIcon status={transaction.status} />
                            <span>{transaction.status}</span>
                          </div>
                        </td>

                        <td className="whitespace-nowrap py-4 sm:pl-4 pr-3 text-sm font-medium text-body-text table-cell rounded-r-3xl">
                          <div className="flex space-x-1">
                            <div
                              className={`text-${currencyFillColor} truncate md:max-w-32 text-sm font-bold w-full flex space-x-1.5 items-center`}
                            >
                              <span>{isCredit ? "+ " : "- "}</span>
                              <span>
                                {/* {GetCurrenySymbol(currencyCode, "13", "13")} */}
                                {currencyCode}
                              </span>
                              <span className="truncate md:max-w-[5rem]">
                                {type === "crypto"
                                  ? FormatBalance(
                                      transaction.crypto_amount,
                                      true
                                    )
                                  : FormatBalance(transaction.amount)}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {index !== transactions.length - 1 && (
                        <tr className="h-2"></tr> // Spacer row
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </table>
          {transactions.length === 0 && (
            <div className="flex items-center justify-center h-3/4">
              <EmptyRecentTransactions />
            </div>
          )}
        </>
      )}
      {loadMore && <LoadMoreTransactions />}
    </div>
  );
};

export default Table;
