import React, { useEffect } from "react";
import TransactionDetail from "../TransactionDetail";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import { setPageTitle } from "../../../helper/metaDataHelper";
import ViewSupportingDocument from "../../Common/ViewSupportingDocument";

const Review = ({
  handlePrev,
  handleTransaction,
  amount,
  supportingDocument,
  message,
  children,
  isTransactionConfirmed,
}) => {

  // const ViewSupportingDocument = ({ supportingDocument }) => {
  //   const openSupportingDocument = (item) => {
  //     const url = URL.createObjectURL(item);
  //     window.open(url, "_blank");
  //   };

  //   return (
  //     <div className="grid md:grid-cols-4 grid-cols-3">
  //       {supportingDocument.supporting_documents.map((doc, index) => (
  //         <li
  //           key={index}
  //           className="flex justify-between items-center hover:bg-option-list-hover rounded-2xl p-2"
  //         >
  //           <span
  //             className="text-primary-item text-sm font-medium underline cursor-pointer px-2"
  //             onClick={() => openSupportingDocument(doc)}
  //           >
  //             View {index + 1}
  //           </span>
  //         </li>
  //       ))}
  //     </div>
  //   );
  // };

  useEffect(() => {
    setPageTitle("Review Transfer");
  }, []);

  return (
    <div className="flex flex-col items-start justify-start">
      <h3 className="text-sm font-bold text-body-text md:mb-4 mb-3 mt-4">
        Review transaction details
      </h3>
      <div className="flex items-center justify-center flex-col">
        <div
          className="flex flex-col shadow-box-shadow dark:bg-input-bg bg-primary-Icon-bg stroke-1 stroke-dropdown-popup-bg items-start 
       w-full rounded-xl border border-divider-border"
        >
          <div className="w-full rounded-t-xl bg-option-list-hover">
            <div className="flex justify-start px-6 max-[360px]:px-3 py-4">
              <h3 className="text-sm font-normal text-primary-item tracking-[0.07px]">
                An amount of exactly{" "}
                <span className="font-semibold">{amount}</span>
                {message}
              </h3>
            </div>
          </div>
          <div className="flex w-full mt-2 justify-start px-6 max-[360px]:px-3 py-4">
            <div className="flex w-full flex-col space-y-4">
              {children}
              {supportingDocument && (
                <TransactionDetail
                  label="Supporting Documents"
                  value={
                    <ViewSupportingDocument
                      supportingDocument={supportingDocument}
                    />
                  }
                />
              )}
            </div>
          </div>
        </div>
        <hr className="w-full mt-4 border-divider-border" />
        <div className="flex space-x-4 mt-4 w-full md:w-[568px]">
          {!isTransactionConfirmed && (
            <NavigationButton onClick={handlePrev} text="Back" />
          )}
          <PrimaryButton onClick={handleTransaction} text="Confirm" />
        </div>
      </div>
    </div>
  );
};

export default Review;
