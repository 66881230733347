import React from "react";
import { DropdownCheckIcon } from "../../Icons";

const OptionButton = ({ lable, value, option, setOption, error }) => {
  return (
    <button
      onClick={() => setOption(value)}
      className="relative w-full rounded-3xl border border-divider-border focus:border-primary-item focus:border outline-none"
    >
      {option === value && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 -top-1 bg-[#EF7D22] rounded-full border-2 shadow border-white">
          <DropdownCheckIcon />
        </div>
      )}
      <div
        className={`
            ${
              option === value
                ? "bg-option-list-selected border-button-bg"
                : "hover:bg-option-list-hover bg-popup-bg border-divider-border"
            }

            ${
              error && !option
                ? "border border-highlighter-red/70 bg-highlighter-red/15 hover:bg-highlighter-red/15"
                : "border-divider-border"
            }

        py-5 px-4 text-body-text border cursor-pointer rounded-3xl w-full text-sm font-medium`}
      >
        <h2>{lable}</h2>
      </div>
    </button>
  );
};

export default OptionButton;
