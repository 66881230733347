import React from "react";
import { DropdownCheckIcon } from "../../Icons";
import InitialsAvatar from "../../Common/InitialsAvatar";

const BeneficiarySelectButton = ({
  beneficiaryName,
  accountNumber,
  swiftCode,
  select,
  beneficiaryId,
}) => {
  return (
    <div className="relative cursor-pointer">
      {select?.id === beneficiaryId && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 top-0 bg-[#EF7D22] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}
      <div
        className={`flex justify-between px-4 py-3 my-3 rounded-3xl border border-divider-border ${
          select.id === beneficiaryId
            ? "border-icon-btn bg-option-list-selected"
            : "border-divider-border bg-popup-bg hover:bg-option-list-hover"
        } `}
      >
        <div className="flex justify-center items-start space-x-3">
          <InitialsAvatar name={beneficiaryName} />
          <div className="flex flex-col space-y-1">
            <div className="text-body-text text-sm font-semibold">
              {beneficiaryName}
            </div>
            <div className="text-paragraph-text text-xs font-normal">
              Account: {accountNumber}
            </div>
            <div className="text-paragraph-text text-xs font-normal">
              Swift code: {swiftCode}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiarySelectButton;
