const DropdownCheckIcon = ({ className, fillColor }) => {
  return (
    <div className={className}>
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" className="fill-[#EF7D22]" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8286 5.42136C16.2863 5.879 16.2863 6.621 15.8286 7.07864L8.95364 13.9536C8.49599 14.4113 7.754 14.4113 7.29636 13.9536L4.17136 10.8286C3.71371 10.371 3.71371 9.629 4.17136 9.17136C4.629 8.71371 5.37099 8.71371 5.82864 9.17136L8.125 11.4677L14.1714 5.42136C14.629 4.96371 15.371 4.96371 15.8286 5.42136Z"
          className="fill-[#172C0F]"
        />
      </svg>
    </div>
  );
};

export default DropdownCheckIcon;
