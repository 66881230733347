import React from "react";

const EmptyCurrencyListIcons = ({ className }) => {
  return (
    <div className={className}>
      <div className="hidden dark:block">
        <svg
          width={150}
          height={138}
          viewBox="0 0 150 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M68.7566 18.8438C51.0632 19.3439 36.8337 38.9221 36.8337 63.0006C36.8337 87.2364 51.2496 106.913 69.1036 107.165C69.2115 107.166 93.4315 107.167 96.6983 107.167C96.944 107.167 97.1754 107.16 97.4192 107.129C100.684 106.721 125.167 102.348 125.167 63.0007C125.167 23.2864 96.6632 19.2019 93.3573 18.8617C93.143 18.8396 92.9462 18.834 92.7307 18.834C89.9016 18.8344 68.9717 18.8377 68.7566 18.8438Z"
            fill="#2A3948"
          />
          <line
            x1="46.208"
            y1="53.625"
            x2="40.708"
            y2="53.625"
            stroke="#636B74"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="46.208"
            y1="71.291"
            x2="40.708"
            y2="71.291"
            stroke="#636B74"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="55.042"
            y1="88.959"
            x2="45.1253"
            y2="88.959"
            stroke="#636B74"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="55.042"
            y1="35.959"
            x2="45.1253"
            y2="35.959"
            stroke="#636B74"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.2533 107.167C88.1312 107.167 70.9776 107.166 70.8558 107.165C52.032 106.913 36.833 87.2364 36.833 63.0006C36.833 38.9221 51.8355 19.3438 70.49 18.8438C70.7332 18.8372 96.5977 18.834 88.1312 18.834"
            stroke="url(#paint0_linear_5030_47532)"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            cx="87.6253"
            cy="63.0006"
            rx="44.1667"
            ry="37.5417"
            transform="rotate(90 87.6253 63.0006)"
            fill="url(#paint1_linear_5030_47532)"
          />
          <ellipse
            cx="87.6253"
            cy="63.0006"
            rx="44.1667"
            ry="37.5417"
            transform="rotate(90 87.6253 63.0006)"
            fill="url(#paint2_linear_5030_47532)"
          />
          <ellipse
            cx="87.6253"
            cy="63.0006"
            rx="44.1667"
            ry="37.5417"
            transform="rotate(90 87.6253 63.0006)"
            stroke="#233342"
            strokeWidth="7.75"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.5008 42.6836C91.5008 40.5435 89.7659 38.8086 87.6258 38.8086C85.4857 38.8086 83.7508 40.5435 83.7508 42.6836V46.5086C81.7727 46.7659 79.722 47.3251 77.94 48.435C75.551 49.9231 73.8324 52.3014 73.4487 55.5324C73.1644 57.9253 73.4167 60.1444 74.3288 62.1133C75.2456 64.0923 76.6848 65.5068 78.2557 66.5182C81.121 68.363 84.8619 69.0967 87.6413 69.6418L87.7937 69.6717L88.0292 69.7179L88.0294 69.718C89.4689 70.0003 90.7202 70.2457 91.8516 70.5515C93.0518 70.8759 93.8538 71.2029 94.3688 71.5273C94.8275 71.8162 94.9148 72.0083 94.9493 72.1001C94.9977 72.2288 95.138 72.725 94.8484 73.9075C94.7307 74.3886 94.4156 74.896 93.3686 75.3916C92.236 75.9278 90.5537 76.2729 88.4737 76.3387C84.2195 76.4733 79.8334 75.4176 78.1697 74.5408C76.2764 73.5431 73.9327 74.2691 72.935 76.1623C71.9372 78.0556 72.6632 80.3993 74.5565 81.397C76.7254 82.54 80.1409 83.4578 83.7508 83.8678V87.7336C83.7508 89.8737 85.4857 91.6086 87.6258 91.6086C89.7659 91.6086 91.5008 89.8737 91.5008 87.7336V83.8569C93.285 83.6126 95.0704 83.1605 96.6843 82.3965C99.2304 81.1914 101.56 79.0822 102.376 75.7506C102.916 73.5469 102.957 71.3765 102.204 69.3732C101.437 67.3331 100.021 65.9279 98.4992 64.9697C97.0343 64.047 95.38 63.477 93.8736 63.0699C92.4673 62.6898 90.96 62.3946 89.5968 62.1277L89.5967 62.1276L89.2865 62.0669C86.1176 61.4448 83.8834 60.9241 82.451 60.0019C81.8468 59.6129 81.5382 59.2385 81.3608 58.8555C81.1787 58.4624 80.9902 57.7464 81.1446 56.4465C81.2326 55.7056 81.5103 55.3416 82.0374 55.0133C82.7337 54.5796 83.9329 54.2172 85.6902 54.1037C89.194 53.8775 93.4562 54.7252 96.1323 55.6253C98.1607 56.3075 100.358 55.2162 101.04 53.1877C101.723 51.1593 100.631 48.9619 98.6029 48.2796C96.7242 47.6477 94.2051 47.0185 91.5008 46.6488V42.6836Z"
            fill="#CACED2"
          />
          <path
            d="M149.277 41.3465C149.307 41.2921 149.325 41.232 149.33 41.1701C149.336 41.1082 149.328 41.0459 149.307 40.9873C149.287 40.9286 149.254 40.875 149.211 40.8299C149.168 40.7848 149.117 40.7493 149.059 40.7256C147.382 40.0571 141.45 38.0726 138.208 42.0734C136.039 44.7029 132.973 46.4362 129.601 46.9392C129.517 46.9505 129.437 46.9873 129.374 47.0446C129.31 47.1019 129.265 47.1771 129.245 47.2603C129.225 47.3435 129.231 47.4308 129.261 47.5108C129.292 47.5908 129.345 47.6597 129.416 47.7086C132.281 49.7408 141.747 54.9196 149.277 41.3465Z"
            fill="url(#paint3_linear_5030_47532)"
          />
          <path
            d="M149.277 41.3465C149.307 41.2921 149.325 41.232 149.33 41.1701C149.336 41.1082 149.328 41.0459 149.307 40.9873C149.287 40.9286 149.254 40.875 149.211 40.8299C149.168 40.7848 149.117 40.7493 149.059 40.7256C147.382 40.0571 141.45 38.0726 138.208 42.0734C136.039 44.7029 132.973 46.4362 129.601 46.9392C129.517 46.9505 129.437 46.9873 129.374 47.0446C129.31 47.1019 129.265 47.1771 129.245 47.2603C129.225 47.3435 129.231 47.4308 129.261 47.5108C129.292 47.5908 129.345 47.6597 129.416 47.7086C132.281 49.7408 141.747 54.9196 149.277 41.3465Z"
            fill="url(#paint4_linear_5030_47532)"
          />
          <path
            d="M23.6198 69.405C23.6437 69.3613 23.6582 69.313 23.6622 69.2633C23.6663 69.2136 23.6598 69.1636 23.6433 69.1166C23.6268 69.0696 23.6006 69.0265 23.5664 68.9903C23.5322 68.954 23.4907 68.9254 23.4447 68.9062C22.1022 68.3756 17.332 66.7838 14.7267 69.9675C12.9882 72.077 10.5315 73.4697 7.82864 73.8781C7.76078 73.8872 7.69725 73.9166 7.64639 73.9624C7.59554 74.0083 7.55973 74.0684 7.54366 74.135C7.52759 74.2015 7.53202 74.2714 7.55637 74.3354C7.58071 74.3994 7.62383 74.4546 7.68007 74.4936C9.96703 76.1491 17.5708 80.3092 23.6198 69.405Z"
            fill="url(#paint5_linear_5030_47532)"
          />
          <path
            d="M23.6198 69.405C23.6437 69.3613 23.6582 69.313 23.6622 69.2633C23.6663 69.2136 23.6598 69.1636 23.6433 69.1166C23.6268 69.0696 23.6006 69.0265 23.5664 68.9903C23.5322 68.954 23.4907 68.9254 23.4447 68.9062C22.1022 68.3756 17.332 66.7838 14.7267 69.9675C12.9882 72.077 10.5315 73.4697 7.82864 73.8781C7.76078 73.8872 7.69725 73.9166 7.64639 73.9624C7.59554 74.0083 7.55973 74.0684 7.54366 74.135C7.52759 74.2015 7.53202 74.2714 7.55637 74.3354C7.58071 74.3994 7.62383 74.4546 7.68007 74.4936C9.96703 76.1491 17.5708 80.3092 23.6198 69.405Z"
            fill="url(#paint6_linear_5030_47532)"
          />
          <path
            d="M136.224 63.3864C136.204 63.35 136.192 63.3097 136.188 63.2682C136.185 63.2266 136.191 63.1849 136.205 63.1457C136.219 63.1065 136.241 63.0707 136.27 63.0408C136.299 63.011 136.334 62.9877 136.373 62.9725C137.498 62.5215 141.483 61.1896 143.658 63.8745C145.117 65.6408 147.177 66.8051 149.442 67.1431C149.502 67.1486 149.559 67.173 149.604 67.2127C149.65 67.2524 149.682 67.3054 149.695 67.3642C149.709 67.4231 149.703 67.4847 149.68 67.5402C149.656 67.5958 149.616 67.6425 149.564 67.6737C147.643 69.048 141.281 72.5236 136.224 63.3864Z"
            fill="url(#paint7_linear_5030_47532)"
          />
          <path
            d="M136.224 63.3864C136.204 63.35 136.192 63.3097 136.188 63.2682C136.185 63.2266 136.191 63.1849 136.205 63.1457C136.219 63.1065 136.241 63.0707 136.27 63.0408C136.299 63.011 136.334 62.9877 136.373 62.9725C137.498 62.5215 141.483 61.1896 143.658 63.8745C145.117 65.6408 147.177 66.8051 149.442 67.1431C149.502 67.1486 149.559 67.173 149.604 67.2127C149.65 67.2524 149.682 67.3054 149.695 67.3642C149.709 67.4231 149.703 67.4847 149.68 67.5402C149.656 67.5958 149.616 67.6425 149.564 67.6737C147.643 69.048 141.281 72.5236 136.224 63.3864Z"
            fill="url(#paint8_linear_5030_47532)"
          />
          <path
            d="M0.0592421 46.1665C0.0264923 46.1058 0.00681559 46.0389 0.00148021 45.9702C-0.00385517 45.9015 0.00526914 45.8324 0.0282651 45.7674C0.051261 45.7024 0.0876183 45.643 0.134994 45.5929C0.182369 45.5428 0.239712 45.5032 0.303326 45.4767C2.16048 44.7391 8.73482 42.5424 12.3271 46.9677C14.7334 49.8854 18.1368 51.8064 21.8782 52.3588C21.9717 52.3725 22.059 52.4141 22.1287 52.4781C22.1983 52.542 22.2472 52.6254 22.2689 52.7175C22.2906 52.8095 22.2842 52.906 22.2505 52.9943C22.2168 53.0827 22.1573 53.1589 22.0798 53.213C18.8908 55.4629 8.40053 61.2041 0.0592421 46.1665Z"
            fill="url(#paint9_linear_5030_47532)"
          />
          <path
            d="M0.0592421 46.1665C0.0264923 46.1058 0.00681559 46.0389 0.00148021 45.9702C-0.00385517 45.9015 0.00526914 45.8324 0.0282651 45.7674C0.051261 45.7024 0.0876183 45.643 0.134994 45.5929C0.182369 45.5428 0.239712 45.5032 0.303326 45.4767C2.16048 44.7391 8.73482 42.5424 12.3271 46.9677C14.7334 49.8854 18.1368 51.8064 21.8782 52.3588C21.9717 52.3725 22.059 52.4141 22.1287 52.4781C22.1983 52.542 22.2472 52.6254 22.2689 52.7175C22.2906 52.8095 22.2842 52.906 22.2505 52.9943C22.2168 53.0827 22.1573 53.1589 22.0798 53.213C18.8908 55.4629 8.40053 61.2041 0.0592421 46.1665Z"
            fill="url(#paint10_linear_5030_47532)"
          />
          <path
            d="M21.9773 27.3433L29.9749 29.5908L24.0298 35.3932L21.9773 27.3433Z"
            fill="url(#paint11_linear_5030_47532)"
          />
          <path
            d="M21.9773 27.3433L29.9749 29.5908L24.0298 35.3932L21.9773 27.3433Z"
            fill="url(#paint12_linear_5030_47532)"
          />
          <path
            d="M133.79 0.8125L136.029 10.0852L145.301 12.3235L136.029 14.5619L133.79 23.8346L131.552 14.5619L122.279 12.3235L131.552 10.0852L133.79 0.8125Z"
            fill="url(#paint13_linear_5030_47532)"
          />
          <path
            d="M133.79 0.8125L136.029 10.0852L145.301 12.3235L136.029 14.5619L133.79 23.8346L131.552 14.5619L122.279 12.3235L131.552 10.0852L133.79 0.8125Z"
            fill="url(#paint14_linear_5030_47532)"
          />
          <path
            d="M51.4983 114.979L53.7367 124.251L63.0094 126.49L53.7367 128.728L51.4983 138.001L49.26 128.728L39.9873 126.49L49.26 124.251L51.4983 114.979Z"
            fill="url(#paint15_linear_5030_47532)"
          />
          <path
            d="M51.4983 114.979L53.7367 124.251L63.0094 126.49L53.7367 128.728L51.4983 138.001L49.26 128.728L39.9873 126.49L49.26 124.251L51.4983 114.979Z"
            fill="url(#paint16_linear_5030_47532)"
          />
          <path
            d="M35.6913 113.699C35.6913 115.553 34.1882 117.057 32.3339 117.057C30.4797 117.057 28.9766 115.553 28.9766 113.699C28.9766 111.845 30.4797 110.342 32.3339 110.342C34.1882 110.342 35.6913 111.845 35.6913 113.699Z"
            fill="url(#paint17_linear_5030_47532)"
          />
          <path
            d="M35.6913 113.699C35.6913 115.553 34.1882 117.057 32.3339 117.057C30.4797 117.057 28.9766 115.553 28.9766 113.699C28.9766 111.845 30.4797 110.342 32.3339 110.342C34.1882 110.342 35.6913 111.845 35.6913 113.699Z"
            fill="url(#paint18_linear_5030_47532)"
          />
          <circle
            cx="36.3906"
            cy="9.96481"
            r="3.35738"
            fill="url(#paint19_linear_5030_47532)"
          />
          <circle
            cx="36.3906"
            cy="9.96481"
            r="3.35738"
            fill="url(#paint20_linear_5030_47532)"
          />
          <circle
            cx="141.284"
            cy="108.484"
            r="3.35738"
            fill="url(#paint21_linear_5030_47532)"
          />
          <circle
            cx="141.284"
            cy="108.484"
            r="3.35738"
            fill="url(#paint22_linear_5030_47532)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5030_47532"
              x1="41.1421"
              y1="75.2343"
              x2="86.0106"
              y2="63.5518"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5030_47532"
              x1="99.859"
              y1="94.4378"
              x2="77.8217"
              y2="34.6934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5030_47532"
              x1="99.859"
              y1="94.4378"
              x2="77.8217"
              y2="34.6934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_5030_47532"
              x1="142.066"
              y1="49.5143"
              x2="139.927"
              y2="40.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_5030_47532"
              x1="142.066"
              y1="49.5143"
              x2="139.927"
              y2="40.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_5030_47532"
              x1="17.8326"
              y1="75.9642"
              x2="16.1144"
              y2="68.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_5030_47532"
              x1="17.8326"
              y1="75.9642"
              x2="16.1144"
              y2="68.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_5030_47532"
              x1="144.817"
              y1="68.8896"
              x2="143.375"
              y2="62.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_5030_47532"
              x1="144.817"
              y1="68.8896"
              x2="143.375"
              y2="62.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_5030_47532"
              x1="14.2266"
              y1="55.2141"
              x2="11.8589"
              y2="45.0017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_5030_47532"
              x1="14.2266"
              y1="55.2141"
              x2="11.8589"
              y2="45.0017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_5030_47532"
              x1="29.0834"
              y1="32.7222"
              x2="21.7204"
              y2="29.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_5030_47532"
              x1="29.0834"
              y1="32.7222"
              x2="21.7204"
              y2="29.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_5030_47532"
              x1="136.979"
              y1="21.9628"
              x2="129.379"
              y2="4.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_5030_47532"
              x1="136.979"
              y1="21.9628"
              x2="129.379"
              y2="4.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_5030_47532"
              x1="54.6868"
              y1="136.129"
              x2="47.0869"
              y2="118.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_5030_47532"
              x1="54.6868"
              y1="136.129"
              x2="47.0869"
              y2="118.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_5030_47532"
              x1="33.2639"
              y1="116.511"
              x2="31.0473"
              y2="111.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_5030_47532"
              x1="33.2639"
              y1="116.511"
              x2="31.0473"
              y2="111.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_5030_47532"
              x1="37.3205"
              y1="12.7763"
              x2="35.1039"
              y2="7.66823"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_5030_47532"
              x1="37.3205"
              y1="12.7763"
              x2="35.1039"
              y2="7.66823"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_5030_47532"
              x1="142.214"
              y1="111.296"
              x2="139.997"
              y2="106.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#203040" />
              <stop offset={1} stopColor="#57616C" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_5030_47532"
              x1="142.214"
              y1="111.296"
              x2="139.997"
              y2="106.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#213141" />
              <stop offset={1} stopColor="#59626D" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="block dark:hidden">
        <svg
          width={150}
          height={138}
          viewBox="0 0 150 138"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M68.7563 18.8438C51.0629 19.3439 36.8334 38.9221 36.8333 63.0006C36.8333 87.2364 51.2493 106.913 69.1033 107.165C69.2112 107.166 93.4312 107.167 96.698 107.167C96.9437 107.167 97.1751 107.16 97.4189 107.129C100.684 106.721 125.167 102.348 125.167 63.0007C125.167 23.2864 96.6629 19.2019 93.357 18.8617C93.1427 18.8396 92.9459 18.834 92.7304 18.834C89.9013 18.8344 68.9714 18.8377 68.7563 18.8438Z"
            fill="#EDECE9"
          />
          <line
            x1="46.2083"
            y1="53.625"
            x2="40.7083"
            y2="53.625"
            stroke="#CDD9E5"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="46.2083"
            y1="71.291"
            x2="40.7083"
            y2="71.291"
            stroke="#CDD9E5"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="55.0417"
            y1="88.959"
            x2="45.125"
            y2="88.959"
            stroke="#CDD9E5"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="55.0417"
            y1="35.959"
            x2="45.125"
            y2="35.959"
            stroke="#CDD9E5"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M88.2536 107.167C88.1315 107.167 70.9779 107.166 70.8561 107.165C52.0323 106.913 36.8333 87.2364 36.8333 63.0006C36.8333 38.9221 51.8358 19.3438 70.4903 18.8438C70.7335 18.8372 96.598 18.834 88.1315 18.834"
            stroke="url(#paint0_linear_1359_53333)"
            strokeWidth="7.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse
            cx="87.625"
            cy="63.0006"
            rx="44.1667"
            ry="37.5417"
            transform="rotate(90 87.625 63.0006)"
            fill="url(#paint1_linear_1359_53333)"
            stroke="url(#paint2_linear_1359_53333)"
            strokeWidth="7.75"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.5008 42.6836C91.5008 40.5435 89.7659 38.8086 87.6258 38.8086C85.4857 38.8086 83.7508 40.5435 83.7508 42.6836V46.5086C81.7727 46.7659 79.722 47.3251 77.94 48.435C75.551 49.9231 73.8324 52.3014 73.4487 55.5324C73.1644 57.9253 73.4167 60.1444 74.3288 62.1133C75.2456 64.0923 76.6848 65.5068 78.2557 66.5182C81.121 68.363 84.8619 69.0967 87.6413 69.6418L87.7937 69.6717L88.0292 69.7179L88.0294 69.718C89.4689 70.0003 90.7202 70.2457 91.8516 70.5515C93.0518 70.8759 93.8538 71.2029 94.3688 71.5273C94.8275 71.8162 94.9148 72.0083 94.9493 72.1001C94.9977 72.2288 95.138 72.725 94.8484 73.9075C94.7307 74.3886 94.4156 74.896 93.3686 75.3916C92.236 75.9278 90.5537 76.2729 88.4737 76.3387C84.2195 76.4733 79.8334 75.4176 78.1697 74.5408C76.2764 73.5431 73.9327 74.2691 72.935 76.1623C71.9372 78.0556 72.6632 80.3993 74.5565 81.397C76.7254 82.54 80.1409 83.4578 83.7508 83.8678V87.7336C83.7508 89.8737 85.4857 91.6086 87.6258 91.6086C89.7659 91.6086 91.5008 89.8737 91.5008 87.7336V83.8569C93.285 83.6126 95.0704 83.1605 96.6843 82.3965C99.2304 81.1914 101.56 79.0822 102.376 75.7506C102.916 73.5469 102.957 71.3765 102.204 69.3732C101.437 67.3331 100.021 65.9279 98.4992 64.9697C97.0343 64.047 95.38 63.477 93.8736 63.0699C92.4673 62.6898 90.96 62.3946 89.5968 62.1277L89.5967 62.1276L89.2865 62.0669C86.1176 61.4448 83.8834 60.9241 82.451 60.0019C81.8468 59.6129 81.5382 59.2385 81.3608 58.8555C81.1787 58.4624 80.9902 57.7464 81.1446 56.4465C81.2326 55.7056 81.5103 55.3416 82.0374 55.0133C82.7337 54.5796 83.9329 54.2172 85.6902 54.1037C89.194 53.8775 93.4562 54.7252 96.1323 55.6253C98.1607 56.3075 100.358 55.2162 101.04 53.1877C101.723 51.1593 100.631 48.9619 98.6029 48.2796C96.7242 47.6477 94.2051 47.0185 91.5008 46.6488V42.6836Z"
            fill="#B9C7D3"
          />
          <path
            d="M149.277 41.3465C149.307 41.2921 149.325 41.232 149.331 41.1701C149.336 41.1082 149.328 41.0459 149.307 40.9873C149.287 40.9286 149.254 40.875 149.211 40.8299C149.169 40.7848 149.117 40.7493 149.059 40.7256C147.383 40.0571 141.45 38.0726 138.208 42.0734C136.039 44.7029 132.973 46.4362 129.602 46.9392C129.517 46.9505 129.437 46.9873 129.374 47.0446C129.31 47.1019 129.266 47.1771 129.245 47.2603C129.225 47.3435 129.231 47.4308 129.261 47.5108C129.292 47.5908 129.346 47.6597 129.416 47.7086C132.281 49.7408 141.748 54.9196 149.277 41.3465Z"
            fill="url(#paint3_linear_1359_53333)"
          />
          <path
            d="M23.6194 69.405C23.6433 69.3613 23.6578 69.313 23.6619 69.2633C23.6659 69.2136 23.6595 69.1636 23.643 69.1166C23.6265 69.0696 23.6002 69.0265 23.566 68.9903C23.5318 68.954 23.4903 68.9254 23.4443 68.9062C22.1019 68.3756 17.3316 66.7838 14.7263 69.9675C12.9878 72.077 10.5312 73.4697 7.82828 73.8781C7.76041 73.8872 7.69689 73.9166 7.64603 73.9624C7.59517 74.0083 7.55936 74.0684 7.54329 74.135C7.52723 74.2015 7.53166 74.2714 7.556 74.3354C7.58034 74.3994 7.62346 74.4546 7.6797 74.4936C9.96666 76.1491 17.5704 80.3092 23.6194 69.405Z"
            fill="url(#paint4_linear_1359_53333)"
          />
          <path
            d="M136.225 63.3864C136.205 63.35 136.192 63.3097 136.189 63.2682C136.185 63.2266 136.191 63.1849 136.205 63.1457C136.219 63.1065 136.241 63.0707 136.27 63.0408C136.299 63.011 136.335 62.9877 136.373 62.9725C137.498 62.5215 141.483 61.1896 143.659 63.8745C145.117 65.6408 147.177 66.8051 149.442 67.1431C149.503 67.1486 149.559 67.173 149.605 67.2127C149.65 67.2524 149.682 67.3054 149.696 67.3642C149.709 67.4231 149.704 67.4847 149.68 67.5402C149.657 67.5958 149.616 67.6425 149.564 67.6737C147.644 69.048 141.282 72.5236 136.225 63.3864Z"
            fill="url(#paint5_linear_1359_53333)"
          />
          <path
            d="M0.0592421 46.1665C0.0264923 46.1058 0.00681559 46.0389 0.00148021 45.9702C-0.00385517 45.9015 0.00526914 45.8324 0.0282651 45.7674C0.051261 45.7024 0.0876183 45.643 0.134994 45.5929C0.182369 45.5428 0.239712 45.5032 0.303326 45.4767C2.16048 44.7391 8.73482 42.5424 12.3271 46.9677C14.7334 49.8854 18.1368 51.8064 21.8782 52.3588C21.9717 52.3725 22.059 52.4141 22.1287 52.4781C22.1983 52.542 22.2472 52.6254 22.2689 52.7175C22.2906 52.8095 22.2842 52.906 22.2505 52.9943C22.2168 53.0827 22.1573 53.1589 22.0798 53.213C18.8908 55.4629 8.40053 61.2041 0.0592421 46.1665Z"
            fill="url(#paint6_linear_1359_53333)"
          />
          <path
            d="M21.9772 27.3433L29.9748 29.5908L24.0296 35.3932L21.9772 27.3433Z"
            fill="url(#paint7_linear_1359_53333)"
          />
          <path
            d="M133.79 0.8125L136.029 10.0852L145.301 12.3235L136.029 14.5619L133.79 23.8346L131.552 14.5619L122.279 12.3235L131.552 10.0852L133.79 0.8125Z"
            fill="url(#paint8_linear_1359_53333)"
          />
          <path
            d="M51.4981 114.979L53.7365 124.251L63.0091 126.49L53.7365 128.728L51.4981 138.001L49.2597 128.728L39.9871 126.49L49.2597 124.251L51.4981 114.979Z"
            fill="url(#paint9_linear_1359_53333)"
          />
          <path
            d="M35.6909 113.699C35.6909 115.553 34.1878 117.057 32.3335 117.057C30.4793 117.057 28.9761 115.553 28.9761 113.699C28.9761 111.845 30.4793 110.342 32.3335 110.342C34.1878 110.342 35.6909 111.845 35.6909 113.699Z"
            fill="url(#paint10_linear_1359_53333)"
          />
          <circle
            cx="36.3902"
            cy="9.96481"
            r="3.35738"
            fill="url(#paint11_linear_1359_53333)"
          />
          <circle
            cx="141.284"
            cy="108.484"
            r="3.35738"
            fill="url(#paint12_linear_1359_53333)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1359_53333"
              x1="41.1424"
              y1="75.2343"
              x2="86.0109"
              y2="63.5518"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1359_53333"
              x1="99.8586"
              y1="94.4378"
              x2="77.8214"
              y2="34.6934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1359_53333"
              x1="99.8586"
              y1="94.4378"
              x2="77.8214"
              y2="34.6934"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E4EAEF" />
              <stop offset={1} stopColor="#CBD7E4" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1359_53333"
              x1="142.066"
              y1="49.5143"
              x2="139.927"
              y2="40.2953"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1359_53333"
              x1="17.8322"
              y1="75.9642"
              x2="16.1141"
              y2="68.5631"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1359_53333"
              x1="144.817"
              y1="68.8896"
              x2="143.375"
              y2="62.6837"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1359_53333"
              x1="14.2266"
              y1="55.2141"
              x2="11.8589"
              y2="45.0017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1359_53333"
              x1="29.0832"
              y1="32.7222"
              x2="21.7202"
              y2="29.7117"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1359_53333"
              x1="136.979"
              y1="21.9628"
              x2="129.379"
              y2="4.44956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_1359_53333"
              x1="54.6865"
              y1="136.129"
              x2="47.0866"
              y2="118.616"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_1359_53333"
              x1="33.2635"
              y1="116.511"
              x2="31.0468"
              y2="111.403"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_1359_53333"
              x1="37.3202"
              y1="12.7763"
              x2="35.1035"
              y2="7.66823"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_1359_53333"
              x1="142.214"
              y1="111.296"
              x2="139.997"
              y2="106.188"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD7E4" />
              <stop offset={1} stopColor="#E4EAEF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default EmptyCurrencyListIcons;
