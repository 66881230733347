import React, { useState } from "react";
import { GreenEllipse, RedEllipse } from "../../../../Icons";
import { FormatBalance } from "../../../../../helper";

export const ActiveCard = ({ activeCard }) => {
  const [hideBalance, setHideBalance] = useState(false);

  const handleHideBalance = () => {
    setHideBalance(!hideBalance);
  };

  return (
    <div
      className={` ${
        activeCard.status === "Active" ? "bg-card-green-bg" : "bg-card-blue-bg"
      } md:block relative rounded-2xl w-[314px] h-[196px] max-[360px]:w-[300px] bg-cover bg-center bg-no-repeat`}
    >
      <div
        className={`${
          activeCard.status !== "Active" && "bg-zinc-700 opacity-60"
        } text-white  w-full h-full rounded-2xl p-1`}
      >
        <div className="p-2 text-white">
          <div className="flex justify-between">
            <span className="flex items-center justify-center space-x-2">
              {activeCard.status === "Active" ? (
                <GreenEllipse />
              ) : (
                <RedEllipse />
              )}
              <p className="text-white">
                {activeCard.status} | · · {activeCard.card_number.slice(-4)}
              </p>
            </span>
            <span
              className="bg-card-hide-balance-btn font-medium rounded-full px-2 cursor-pointer"
              onClick={handleHideBalance}
            >
              <span className=" text-black dark:text-white">
                {hideBalance ? "Show" : "Hide"} balance
              </span>
            </span>
          </div>
        </div>
        <div>
          <p className="text-white text-xs pt-8 px-2 py-1">Card balance</p>

          <p
            className={`text-white text-2xl font-medium px-2 ${
              hideBalance && "blur-[8px]"
            }`}
          >
            $ {FormatBalance(activeCard.balance)}
          </p>

          <div className="dark:text-white text-button-text text-base font-medium leading-normal tracking-tight pt-8 px-2 ">
            {activeCard.name.toUpperCase()}
          </div>
        </div>
      </div>
    </div>
  );
};
