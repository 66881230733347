import React from "react";
import { useSelector } from "react-redux";

const Logo = ({ className }) => {
  const company = useSelector((state) => state.ownCompany.company);

  return (
    <div className={className}>
      { !company || !company.client_portal_light_logo || !company.client_portal_dark_logo ? (
        <svg
          // width={199}
          // height={55}
          viewBox="0 0 275 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_17_70)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.8167 14.9529C9.98536 15.3971 3.98829 18.0061 1.37742 22.8029C-4.50388 33.6026 8.99139 51.3995 31.514 62.5453C54.0358 73.6901 77.0711 73.9715 82.951 63.1719C85.7653 58.0048 84.1424 51.2355 79.2192 44.3603C79.8369 47.2386 81.9742 51.4224 79.1496 57.1986C73.9458 66.7554 53.564 66.508 33.6352 56.6453C13.7063 46.7832 1.76491 31.0357 6.96885 21.4792C8.84861 18.0272 12.7113 15.8545 17.8167 14.9529Z"
              fill="#EF7D22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M66.5118 14.9529C74.3432 15.3971 80.3391 18.0061 82.9514 22.8029C88.8324 33.6026 75.3374 51.3995 52.8143 62.5453C30.2916 73.6901 7.25781 73.9715 1.37748 63.1719C-2.2158 56.5733 1.42365 47.3651 9.93397 38.6555C4.46654 45.3779 2.43113 52.1527 5.17887 57.1986C10.3828 66.7554 30.7646 66.508 50.6935 56.6453C70.6221 46.7832 82.5639 31.0357 77.36 21.4792C75.4792 18.0272 71.6172 15.8545 66.5118 14.9529Z"
              fill="#EF7D22"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.3696 18.4381V58.1142C45.5681 57.259 47.6958 56.3188 49.7175 55.3255L49.9168 55.2275C50.955 54.7132 51.9984 54.1693 53.0388 53.5968V18.4393C53.0388 17.1673 51.9595 16.1378 50.6246 16.1378L45.7867 16.1369C44.4495 16.1369 43.3696 17.1661 43.3696 18.4381ZM55.4563 6.9199V52.2103C58.8353 50.1934 62.1362 47.8778 65.1239 45.3079V6.9199C65.1239 5.647 64.0421 4.61206 62.7068 4.61206H57.8732C56.5357 4.61206 55.4563 5.647 55.4563 6.9199Z"
              className="fill-[#144B83]"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.2885 53.594V2.30823C31.2885 1.03627 32.3673 0.00134277 33.7024 0.00134277H38.5392C39.8768 0.00134277 40.9561 1.03627 40.9561 2.30823V58.1106C38.6861 57.2283 36.4894 56.2538 34.4099 55.2245C33.3724 54.7102 32.329 54.1663 31.2885 53.594Z"
              className="fill-[#144B83]"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M40.9541 2.56494V58.1142C39.9196 57.7115 38.8996 57.2902 37.9003 56.8529L40.9541 2.56494Z"
              className="fill-[#144B83]"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.2073 45.3056V11.5275C19.2073 10.2555 20.286 9.22058 21.6213 9.22058H26.4582C27.7955 9.22058 28.8747 10.2555 28.8747 11.5275V52.208C25.4961 50.1907 22.1948 47.8753 19.2073 45.3056Z"
              className="fill-[#144B83]"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M28.8765 11.5273V52.2079C27.8865 51.6162 26.9033 50.9994 25.9325 50.3579L28.8765 11.5273Z"
              className="fill-[#144B83]"
            />
            <path
              d="M269.802 33.2398L268.681 30.0312H261.227L260.106 33.2398H256.126L262.67 15.4147H267.238L273.809 33.2398H269.802ZM262.402 26.7463H267.532L264.966 19.4635L262.402 26.7463Z"
              fill="#EF7D22"
            />
            <path
              d="M250.633 15.4147H254.319V33.2398H250.633V15.4147Z"
              fill="#EF7D22"
            />
            <path
              d="M241.578 33.5958C239.726 33.5958 238.167 33.2053 236.905 32.4244C235.64 31.6265 234.749 30.5485 234.232 29.1905L237.385 27.4335C238.115 29.25 239.548 30.1581 241.686 30.1581C242.718 30.1581 243.476 29.9799 243.956 29.6234C244.436 29.2669 244.676 28.817 244.676 28.2737C244.676 27.6456 244.384 27.1618 243.795 26.8223C243.208 26.4658 242.157 26.0838 240.644 25.6764C239.806 25.4388 239.095 25.2012 238.506 24.9634C237.937 24.7258 237.358 24.4117 236.771 24.0213C236.2 23.6138 235.764 23.1045 235.462 22.4934C235.158 21.8821 235.007 21.1692 235.007 20.3543C235.007 18.7416 235.603 17.4599 236.796 16.5092C238.009 15.5416 239.459 15.0577 241.152 15.0577C242.664 15.0577 243.991 15.4142 245.131 16.1273C246.29 16.8233 247.188 17.7994 247.829 19.0557L244.731 20.7617C243.983 19.2338 242.79 18.4699 241.152 18.4699C240.385 18.4699 239.781 18.6397 239.335 18.9792C238.907 19.3018 238.694 19.7262 238.694 20.2525C238.694 20.8127 238.934 21.2711 239.415 21.6276C239.914 21.9671 240.857 22.3405 242.246 22.748C242.815 22.9178 243.243 23.0535 243.528 23.1554C243.832 23.2404 244.231 23.3845 244.731 23.5884C245.248 23.775 245.639 23.9533 245.906 24.123C246.191 24.2928 246.512 24.522 246.867 24.8107C247.223 25.0992 247.49 25.3963 247.668 25.7018C247.864 26.0074 248.025 26.3808 248.149 26.8223C248.292 27.2467 248.364 27.7136 248.364 28.2229C248.364 29.8695 247.73 31.1768 246.468 32.1444C245.221 33.1121 243.59 33.5958 241.578 33.5958Z"
              fill="#EF7D22"
            />
            <path
              d="M229.784 33.2398L228.663 30.0312H221.208L220.087 33.2398H216.107L222.651 15.4147H227.22L233.791 33.2398H229.784ZM222.384 26.7463H227.514L224.948 19.4635L222.384 26.7463Z"
              fill="#EF7D22"
            />
            <path
              d="M201.858 29.8785H208.988V33.2398H198.17V15.4147H201.858V29.8785Z"
              fill="#EF7D22"
            />
            <path
              d="M192.353 33.2398L191.23 30.0312H183.778L182.656 33.2398H178.676L185.22 15.4147H189.788L196.36 33.2398H192.353ZM184.953 26.7463H190.082L187.518 19.4635L184.953 26.7463Z"
              fill="#EF7D22"
            />
            <path
              d="M174.182 33.2398L170.415 27.0519H167.611V33.2398H163.924V15.4147H171.404C173.131 15.4147 174.6 15.9919 175.811 17.1463C177.022 18.3006 177.628 19.6927 177.628 21.3223C177.628 22.4258 177.298 23.453 176.639 24.4036C175.98 25.3374 175.108 26.0419 174.022 26.5172L178.162 33.2398H174.182ZM167.611 18.6995V23.9707H171.404C172.098 23.9707 172.695 23.7161 173.193 23.2067C173.692 22.6806 173.941 22.0523 173.941 21.3223C173.941 20.5924 173.692 19.9728 173.193 19.4635C172.695 18.9543 172.098 18.6995 171.404 18.6995H167.611Z"
              fill="#EF7D22"
            />
            <path
              d="M161.87 15.4147V18.7759H156.822V33.2398H153.135V18.7759H148.114V15.4147H161.87Z"
              fill="#EF7D22"
            />
            <path
              d="M142.342 15.4147H146.029V33.2398H143.224L135.21 22.3411V33.2398H131.524V15.4147H134.329L142.342 26.2879V15.4147Z"
              fill="#EF7D22"
            />
            <path
              d="M121.048 29.8785H128.929V33.2398H117.362V15.4147H128.795V18.7759H121.048V22.5702H128.127V25.8805H121.048V29.8785Z"
              fill="#EF7D22"
            />
            <path
              d="M107.053 33.5958C104.24 33.5958 101.916 32.7047 100.082 30.922C98.2474 29.1396 97.3302 26.9412 97.3302 24.3268C97.3302 21.6955 98.2474 19.4971 100.082 17.7315C101.916 15.949 104.24 15.0577 107.053 15.0577C108.745 15.0577 110.303 15.4397 111.728 16.2036C113.171 16.9506 114.292 17.9692 115.094 19.2593L111.915 21.0165C111.452 20.2186 110.793 19.5988 109.938 19.1576C109.084 18.6992 108.122 18.4699 107.053 18.4699C105.237 18.4699 103.768 19.0132 102.646 20.0997C101.542 21.1861 100.99 22.5951 100.99 24.3268C100.99 26.0413 101.542 27.442 102.646 28.5285C103.768 29.6149 105.237 30.1581 107.053 30.1581C108.122 30.1581 109.084 29.9375 109.938 29.496C110.811 29.0376 111.47 28.4181 111.915 27.6372L115.094 29.3941C114.292 30.6844 113.179 31.7114 111.755 32.4754C110.33 33.2223 108.763 33.5958 107.053 33.5958Z"
              fill="#EF7D22"
            />
            <path
              d="M275.01 57.5261H271.706L266.512 51.0299V57.5261H263.646V43.6622H266.512V49.7822L271.499 43.6622H274.802L269.421 50.3961L275.01 57.5261Z"
              className="fill-[#144B83]"
            />
            <path
              d="M258.08 43.6622H260.946V57.5261H258.765L252.531 49.0493V57.5261H249.665V43.6622H251.846L258.08 52.1193V43.6622Z"
              className="fill-[#144B83]"
            />
            <path
              d="M245.128 57.5261L244.254 55.0307H238.458L237.586 57.5261H234.49L239.579 43.6622H243.133L248.244 57.5261H245.128ZM239.372 52.4758H243.361L241.367 46.8114L239.372 52.4758Z"
              className="fill-[#144B83]"
            />
            <path
              d="M231.999 50.3368C233.274 51.0365 233.911 52.0929 233.911 53.5056C233.911 54.6808 233.473 55.6447 232.602 56.3972C231.728 57.1499 230.655 57.5261 229.381 57.5261H223.211V43.6622H228.945C230.191 43.6622 231.236 44.0318 232.083 44.7714C232.941 45.4975 233.37 46.4283 233.37 47.5639C233.37 48.7126 232.913 49.6369 231.999 50.3368ZM228.945 46.2171H226.077V49.2672H228.945C229.389 49.2672 229.755 49.122 230.045 48.8315C230.351 48.5411 230.502 48.1779 230.502 47.7422C230.502 47.3065 230.357 46.9434 230.067 46.6528C229.776 46.3624 229.402 46.2171 228.945 46.2171ZM229.381 54.9712C229.865 54.9712 230.268 54.8195 230.586 54.5158C230.905 54.1988 231.064 53.8028 231.064 53.3274C231.064 52.8653 230.905 52.4824 230.586 52.1787C230.268 51.8617 229.865 51.7033 229.381 51.7033H226.077V54.9712H229.381Z"
              className="fill-[#144B83]"
            />
            <path
              d="M216.413 43.6622V46.2766H212.486V57.5261H209.62V46.2766H205.714V43.6622H216.413Z"
              className="fill-[#144B83]"
            />
            <path
              d="M201.228 43.6622H204.096V57.5261H201.914L195.682 49.0493V57.5261H192.815V43.6622H194.996L201.228 52.1193V43.6622Z"
              className="fill-[#144B83]"
            />
            <path
              d="M184.66 54.9118H190.789V57.5261H181.793V43.6622H190.685V46.2766H184.66V49.2276H190.165V51.8023H184.66V54.9118Z"
              className="fill-[#144B83]"
            />
            <path
              d="M179.086 43.6622V57.5261H176.24V48.5344L172.147 54.9712H171.815L167.722 48.5542V57.5261H164.855V43.6622H167.805L171.981 50.2179L176.136 43.6622H179.086Z"
              className="fill-[#144B83]"
            />
            <path
              d="M163.254 43.6622V46.2766H159.327V57.5261H156.46V46.2766H152.554V43.6622H163.254Z"
              className="fill-[#144B83]"
            />
            <path
              d="M147.019 57.8031C145.578 57.8031 144.366 57.4994 143.383 56.892C142.399 56.2715 141.707 55.433 141.305 54.3768L143.757 53.0101C144.325 54.423 145.44 55.1293 147.102 55.1293C147.905 55.1293 148.494 54.9907 148.868 54.7136C149.242 54.4363 149.429 54.0862 149.429 53.6638C149.429 53.1752 149.2 52.7989 148.743 52.5349C148.286 52.2576 147.469 51.9606 146.291 51.6436C145.64 51.4588 145.086 51.2738 144.629 51.089C144.186 50.9042 143.736 50.66 143.279 50.3563C142.836 50.0393 142.496 49.6433 142.261 49.1679C142.025 48.6925 141.908 48.1379 141.908 47.5042C141.908 46.2499 142.372 45.253 143.3 44.5136C144.242 43.7609 145.37 43.3846 146.686 43.3846C147.863 43.3846 148.895 43.6619 149.782 44.2165C150.682 44.7578 151.382 45.5171 151.88 46.4941L149.47 47.8211C148.888 46.6328 147.96 46.0387 146.686 46.0387C146.091 46.0387 145.62 46.1707 145.273 46.4347C144.941 46.6856 144.775 47.0157 144.775 47.425C144.775 47.8606 144.962 48.2171 145.336 48.4944C145.723 48.7586 146.458 49.049 147.538 49.366C147.981 49.498 148.314 49.6036 148.535 49.6828C148.771 49.7489 149.082 49.8611 149.47 50.0195C149.872 50.1648 150.177 50.3035 150.384 50.4355C150.606 50.5675 150.855 50.7458 151.132 50.9703C151.409 51.1946 151.617 51.4258 151.756 51.6634C151.908 51.9011 152.033 52.1915 152.13 52.5349C152.24 52.865 152.296 53.2282 152.296 53.6242C152.296 54.905 151.804 55.9217 150.821 56.6742C149.851 57.4269 148.584 57.8031 147.019 57.8031Z"
              className="fill-[#144B83]"
            />
            <path
              d="M133.899 54.9118H140.028V57.5261H131.032V43.6622H139.924V46.2766H133.899V49.2276H139.405V51.8023H133.899V54.9118Z"
              className="fill-[#144B83]"
            />
            <path
              d="M121.178 57.5261L116.296 43.6622H119.412L122.965 54.3177L126.497 43.6622H129.634L124.731 57.5261H121.178Z"
              className="fill-[#144B83]"
            />
            <path
              d="M112.016 43.6622H114.883V57.5261H112.702L106.469 49.0493V57.5261H103.602V43.6622H105.783L112.016 52.1193V43.6622Z"
              className="fill-[#144B83]"
            />
            <path
              d="M98.0414 43.6622H100.909V57.5261H98.0414V43.6622Z"
              className="fill-[#144B83]"
            />
          </g>
          <defs>
            <clipPath id="clip0_17_70">
              <rect width={275} height={72} fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <>
          <img
            src={company.client_portal_light_logo}
            alt="light logo"
            width={199}
            height={55}
            className={className + " dark:hidden block"}
          />
          <img
            src={company.client_portal_dark_logo}
            alt="dark logo"
            width={199}
            height={55}
            className={className + " hidden dark:block"}
          />
        </>
      )}
    </div>
  );
};

export default Logo;
