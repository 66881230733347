import React from "react";
import { FormatBalance } from "../../../../../helper";
import { DropdownCheckIcon } from "../../../../Icons";

const Account = ({ activeAccount, ...account }) => {
  return (
    <div className="relative">
      {activeAccount?.id === account.id && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 top-0 bg-[#EF7D22] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}
      <div
        className={`
      flex items-start w-auto px-[14px] py-[21px] justify-between border-2 rounded-3xl shadow-sm cursor-pointer hover:shadow-sm transition duration-300 my-2
      ${
        activeAccount?.id === account.id
          ? "border-2 border-icon-btn bg-option-list-selected"
          : "bg-popup-bg border-divider-border hover:bg-option-list-hover"
      }
      `}
      >
        <div className="flex space-x-2 items-center">
          <img
            alt="logo"
            src={account.logo}
            className="rounded-full w-10 h-10"
          />
          <div className="flex flex-col justify-start items-start">
            <p className="text-body-text md:flex-nowrap line-clamp-1 max-[360px]:w-20 max-w-24 text-sm font-medium leading-tight tracking-tight">
              {account.currency_code} · {account.title}
            </p>
            <p className="text-paragraph-text text-sm font-normal leading-tight tracking-tight line-clamp-1 max-w-32">
              {account.account_number}
            </p>
          </div>
        </div>
        <div className="flex justify-start space-x-1 items-center">
          {/* <span>{GetCurrenySymbol(account.currency_code, "13", "13")}</span> */}
          <span className="text-sm text-body-text">
            {account.currency_code}
          </span>
          <p className="break-all text-paragraph-text text-sm font-normal leading-tight tracking-tight overflow-hidden text-ellipsis">
            {FormatBalance(account.balance, account.is_crypto)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Account;
